.outer {
  width:100vw;
  height:100vh;
  background-color: gray;
  text-align: center;
  position: absolute;
}
.inner {
  background-color: white;
  width:25%;
  height:30%;
  margin:auto;
  padding:30px;
  margin-top:10%;
}

.Login {
  margin-top: 20px;
  width:100%;
}
.form-email {
  display: flex;
  align-items: baseline;
}
.form-password {
  margin-top: 5px;
}
.login {
  padding-right: 20px;
  padding-left:20px;
}
