.textFieldContainer {

}

.textFieldWrapper {
  display: inline-block;
  margin-right: 20px;
}
.textFieldWrapper > div {
  min-width: 200px;
  width: 100%;
}

.textField {
  background-color: #fff;
}

.textField-select > div {
  background-color: #fff;
}

.textFieldWrapper.textFieldLarge {
  width: 40%;
}
.textFieldWrapper.textFieldSmall {
  width: 15%;
}
.textFieldWrapper.textFieldSmall > div {
  min-width: 80px;
}

.buttonsContainer {
  margin-top: 20px;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
}

.addButton_ui {
  background-color: #42b16a !important;
}
.addButton_ui.abort{
  background-color: #d62828 !important;
}
.uploadimage {
  margin:auto;
}
.previewImage {
  max-width:200px;
  max-height:100%;
  padding:10px;
}

.MuiTableRow-hover {
  cursor: pointer;
}
