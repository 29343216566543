.results {
  margin-top: 30px;
}

.previewImagePerson {
  max-width:200px;
  max-height:100%;
  padding:10px;
  float: left;
}
.infoDisplay {
  padding: 10px;
}
