.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Wrapper {
  position: relative;
}


#mainMenu {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin-left: 30px;
}

.menuItem {
  text-decoration: none;
  padding: 15px;
  position: relative;
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
  box-shadow: -4px 3px 4px -2px #ddd;
  background-color: #fff;
  list-style: none;
  color:black;
}

.menuItem-is-active {
  background-color: #f5f5f5;
}

#MenuWrapper {
  width: 20%;
  float: left;
  position: fixed;
  height: 100% !important;
  box-shadow: 4px 0 4px -2px #ddd;
}

#topBar {
  background-color: #282c34;
  min-height: 30px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;
}
#topBar_placeholder {
  min-height: 40px;
}

#Content {
  clear: both;
  overflow: auto;
  width: 80%;
  margin-left: 20%;
  float: left;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;
  z-index: 200;
}

#Content:after {
  clear: both;
  content: "";
}

#innerContent {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #e7e7e7;
  position: relative;
  margin-bottom: 30px;
}

.clearfix {
  clear: both;
}

.clearfix:after {
  clear: both;
  content: "";
}
.spacing {
  height: 60%;
}

.bottomleftLogout {
  position: absolute;
}
